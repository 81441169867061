import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 100svh;
  width: 100vw;
  gap: 8rem;
  padding: 4rem;
`;

const Meta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 780px;
`;
const Title = styled.h1`
  ${({ theme }) => theme.fonts.primary};
  font-size: 3.8rem;
  line-height: 1em;
  letter-spacing: -0.04em;
`;
const Caption = styled.p`
  ${({ theme }) => theme.fonts.captionRegular};
  font-size: 1.2rem;
  line-height: 1.4em;
  color: ${({ theme }) => theme.colors.charcoal};
  max-width: 25rem;
  letter-spacing: 0.02em;
`;

const Links = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 0.2rem;
  ${({ theme }) => theme.fonts.captionRegular};
  line-height: 1em;
  max-width: 780px;
  font-size: 1.2rem;

  a {
    letter-spacing: 0.02em;
    padding: 1rem;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4em;
  }
`;

const StyledIcon = styled.i`
  font-size: 1.2em;
  line-height: 1em;
`;

const Home = () => {
  return (
    <Wrapper>
      <Meta>
        <Title>TYRON SHI</Title>
        <Caption>Designer / Developer Cinematographer / Musician.</Caption>
      </Meta>
      <Links>
        <Link to="https://www.instagram.com/tyron.shi/">
          Instagram
          <StyledIcon className="material-icons" aria-hidden="true">
            north_east
          </StyledIcon>
        </Link>
        <Link to="https://www.tiktok.com/@tyron.shi">
          TikTok
          <StyledIcon className="material-icons" aria-hidden="true">
            north_east
          </StyledIcon>
        </Link>
        <Link to="https://music.apple.com/us/artist/tyron/1331235344">
          Apple Music
          <StyledIcon className="material-icons" aria-hidden="true">
            north_east
          </StyledIcon>
        </Link>
        <Link to="https://open.spotify.com/artist/5SMwJ4GtRRsLWN5SCJcc8u">
          Spotify
          <StyledIcon className="material-icons" aria-hidden="true">
            north_east
          </StyledIcon>
        </Link>
        <Link to="https://www.etsy.com/shop/TyronShiPhoto">
          Preset Store
          <StyledIcon className="material-icons" aria-hidden="true">
            north_east
          </StyledIcon>
        </Link>
      </Links>
      <Caption>V2 Portfolio coming soon.</Caption>
    </Wrapper>
  );
};
export default Home;
